import proj4 from 'proj4';
import useIsMounted from 'utils/hooks/useIsMounted';
import { Radars } from 'types/imageRadars';
import { GeoLocation } from 'types/locationAPI';
import { maps } from './mapMarkerConfigs';
import { createFavoriteHref } from 'utils/createUrl';

import styles from './MapMarker.module.scss';

type Size = {
	width: number;
	height: number;
};

interface IGetXYFromLatLon {
	lat: number;
	lon: number;
	bounds: [[number]];
	size: Size;
}

interface IXYLatLon {
	x: number;
	y: number;
	xPercentage: number;
	yPercentage: number;
}

interface IMapMarker {
	locations: GeoLocation[];
	radar: Radars;
	size: Size;
}

interface IMarker {
	isWebMercatorRadar: boolean;
	location: GeoLocation;
	bounds: [[number]];
	size: Size;
}

export default function MapMarker({ locations, radar, size }: IMapMarker) {
	const isMounted = useIsMounted();
	if (!isMounted) return null;

	const mapConfig = maps[radar];
	const isWebMercatorRadar = mapConfig?.map?.isWebMercator ?? false;

	if (
		!mapConfig ||
		locations.length <= 0 ||
		Object.entries(mapConfig).length <= 0
	)
		return null;

	return (
		<div className={styles.markerContainer}>
			{locations.map((location) => (
				<Marker
					key={location.id}
					isWebMercatorRadar={isWebMercatorRadar}
					location={location}
					bounds={mapConfig.map.bounds}
					size={size}
				/>
			))}
		</div>
	);
}

function Marker({ isWebMercatorRadar, location, bounds, size }: IMarker) {
	if (!location.location) return null;
	const { lat, lon } = location.location;
	if (!lat || !lon) return null;
	const position = isWebMercatorRadar
		? getXYFromLatLongWebMercator({
				lat,
				lon,
				bounds,
				size,
		  })
		: getXYFromLatLong({
				lat,
				lon,
				bounds,
				size,
		  });

	/* Check if the location can be placed within the boundaries of the image map.
	 * If the x/ y percentage is not between 0 and 100 the location would be placed outside the visible area of the map
	 */
	if (
		position.xPercentage < 100 &&
		position.xPercentage > 0 &&
		position.yPercentage < 100 &&
		position.yPercentage > 0
	) {
		/* Offset is used to place the marker on top of the calculated position.
		 * Without the offset the marker would 'dangle' on the bottom-right of the position.
		 * And the offset is set to '4' because the marker itself has a width and height of 8px.
		 */
		const markerOffset = 4;
		return (
			<a
				href={createFavoriteHref({ favorite: location })}
				title={`Bekijk het weer voor ${location.name}`}
				className={styles.marker}
				style={{
					left: position.x - markerOffset,
					top: position.y - markerOffset,
				}}
			>
				&nbsp;
			</a>
		);
	}

	return null;
}

function getXYFromLatLongWebMercator({
	lat,
	lon,
	bounds,
	size,
}: IGetXYFromLatLon): IXYLatLon {
	//source coordinates will be in Longitude/Latitude, WGS84
	const source = new proj4.Proj(
		'+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
	);
	//destination coordinates in meters, global spherical mercators projection, see http://spatialreference.org/ref/epsg/3785/
	const dest = new proj4.Proj(
		'+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
	);
	const latLonBounds = getBoundsFrom2dArray(bounds);
	const { width, height } = size;

	const boundResultStart = proj4.transform(source, dest, [
		latLonBounds.longitudeStart,
		latLonBounds.latitudeStart,
	]);
	const boundResultEnd = proj4.transform(source, dest, [
		latLonBounds.longitudeEnd,
		latLonBounds.latitudeEnd,
	]);
	const pointResult = proj4.transform(source, dest, [lon, lat]);

	// meters per pixel
	const mppX = (boundResultEnd.x - boundResultStart.x) / width;
	const mppY = (boundResultStart.y - boundResultEnd.y) / height;

	const x = (pointResult.x - boundResultStart.x) / mppX;
	const y = (boundResultStart.y - pointResult.y) / mppY;

	const xPercentage = (100 / width) * x;
	const yPercentage = (100 / height) * y;

	return { x: x, y: y, xPercentage: xPercentage, yPercentage: yPercentage };
}

function getXYFromLatLong({
	lat,
	lon,
	bounds,
	size,
}: IGetXYFromLatLon): IXYLatLon {
	const latLonBounds = getBoundsFrom2dArray(bounds);
	const { width, height } = size;

	const xpix =
		(latLonBounds.longitudeEnd - latLonBounds.longitudeStart) / width;
	const ypix =
		(latLonBounds.latitudeStart - latLonBounds.latitudeEnd) / height;

	const x = Math.round((lon - latLonBounds.longitudeStart) / xpix);
	const y = Math.round((latLonBounds.latitudeStart - lat) / ypix);

	const xPercentage = Math.round(((100 * x) / width) * 1000) / 1000;
	const yPercentage = Math.round(((100 * y) / height) * 1000) / 1000;

	return { x: x, y: y, xPercentage: xPercentage, yPercentage: yPercentage };
}

function getBoundsFrom2dArray(bounds) {
	return {
		longitudeStart: bounds[0][1],
		longitudeEnd: bounds[1][1],
		latitudeStart: bounds[0][0],
		latitudeEnd: bounds[1][0],
	};
}
