import { Radars } from 'types/imageRadars';

/* NOTE: This file is config that is copied over from the existing implementation.
 * See https://dev.azure.com/buienradar/Buienradar.V3/_git/Buienradar.V3?path=/src/Buienradar/Resources/Js/buienradar/buienradar.data.js
 * Some minor changes have been made to make it a bit more maintainable, but there are some things still need further investigations (see the TODOs)
 */

const mapMarkerConfigs = {
	nlWeather: {
		bounds: [
			[53.749, 3.125],
			[50.681, 7.598],
		],
	},
	beWeather: {
		bounds: [
			[51.902, 1.982],
			[49.1, 7],
		],
	},
	nlRadar: {
		bounds: [
			[54.8, 0],
			[49.5, 10],
		],
	},
	nlRadarWebMercator: {
		bounds: [
			[54.8, 0],
			[49.5, 10],
		],
		isWebMercator: true,
	},
	nlRadarCloudHeightWebMercator: {
		bounds: [
			[55.975153, 0.000028],
			[48.897786, 10.860747],
		],
	},
	beRadar: {
		bounds: [
			[52.5, 1],
			[49.1, 7],
		],
	},
	beRadarWebMercator: {
		bounds: [
			[52.5, 1],
			[49.1, 7],
		],
		isWebMercator: true,
	},
	euRadar: {
		bounds: [
			[60, -11],
			[42, 20],
		],
	},
	euRadarWebMercator: {
		bounds: [
			[60.011369, -14.977156],
			[41.433869, 20.443878],
		],
	},
	euGfs: {
		bounds: [
			[71.5, -43],
			[28, 52],
		],
		isWebMercator: true,
	},
	eumetsat: {
		bounds: [
			[-65, 65],
			[65, -65],
		],
	},
};

const leafletTiles = {
	eu: 'eu-v1',
	world: 'world-v1',
	worldBorders: 'world-borders-v1',
	euV2: 'eu-v2',
	worldV2: 'world-v2',
	worldBordersV2: 'world-borders-v2',
};

const leafletConfigs = {
	nl: {
		minZoom: 7,
		maxZoom: 11,
		zoom: 7,
		center: [52, 5.2],
		bounds: mapMarkerConfigs.nlRadar.bounds,
		tiles: leafletTiles.euV2,
	},
	eu: {
		minZoom: 4,
		maxZoom: 11,
		zoom: 6,
		center: [52, 5.2],
		tiles: leafletTiles.euV2,
	},
	euGfs: {
		minZoom: 4,
		maxZoom: 6,
		zoom: 4,
		center: [48, 11],
		bounds: mapMarkerConfigs.euGfs.bounds,
		tiles: leafletTiles.worldV2,
	},
	euCombined: {
		minZoom: 4,
		maxZoom: 6,
		zoom: 4,
		center: [48, 11],
		bounds: mapMarkerConfigs.euGfs.bounds,
		tiles: leafletTiles.worldBordersV2,
	},
	nlQuakes: {
		minZoom: 5,
		maxZoom: 11,
		zoom: 6,
		center: [52, 5.2],
		bounds: mapMarkerConfigs.euGfs.bounds,
		tiles: leafletTiles.euV2,
	},
	eumetsat: {
		minZoom: 1,
		maxZoom: 6,
		zoom: 4,
		center: [0, 0],
		bounds: mapMarkerConfigs.eumetsat.bounds,
		tiles: leafletTiles.worldBordersV2,
	},
	nlRadarCloudHeightWebMercator: {
		minZoom: 6,
		maxZoom: 11,
		zoom: 7,
		center: [52, 5.2],
		bounds: mapMarkerConfigs.nlRadarCloudHeightWebMercator.bounds,
		//tiles: tilesV2 ? leafletTiles.euV2 : leafletTiles.eu //TODO: figure out if V2 is actually used.
		tiles: leafletTiles.eu,
	},
	world: {
		minZoom: 3,
		maxZoom: 8,
		zoom: 4,
		center: [0, 0],
		tiles: leafletTiles.worldBordersV2,
	},
};

export const maps = {
	[Radars.WeatherMapActualTemperature1hBE]: {
		mapConfig: mapMarkerConfigs.beRadar,
	},
	//Weather mapMarkerConfigs
	[Radars.WeatherMapTemperatureActualNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTemperatureMinNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTemperatureGroundNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTemperatureGroundMinNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTemperatureMaxNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTemperatureFeelNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindMaxNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindGustsNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindGustsMaxNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapVisibilityNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapRainfallLastHourNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapRainfallLast24HoursNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapHumidityNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTotalRainfallForDateNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapSunshineNL]: {
		map: mapMarkerConfigs.nlWeather,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapAirQualityOzoneNL]: {
		map: mapMarkerConfigs.nlWeather,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapAirQualityNO2NL]: {
		map: mapMarkerConfigs.nlWeather,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapAirQualityPM10NL]: {
		map: mapMarkerConfigs.nlWeather,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapAirQualityLKINL]: {
		map: mapMarkerConfigs.nlWeather,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapMosquitoRadarNL]: {
		map: mapMarkerConfigs.nlWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapPollenRadarNL]: {
		map: mapMarkerConfigs.nlWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapPollenRadarHourlyNL]: {
		map: mapMarkerConfigs.nlWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapUVIndexNL]: {
		map: mapMarkerConfigs.nlWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapBBQRadarNL]: {
		map: mapMarkerConfigs.nlWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},

	[Radars.WeatherMapMosquitoRadarBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapPollenRadarBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapPollenRadarHourlyBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapUVIndexBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapFeelTemperature1hBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapHumidityBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapMinTemperature1hBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapMaxTemperature1hBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindMaxBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapWindGustsBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapRainfallLastHourBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapTotalRainfallForDateBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},
	[Radars.WeatherMapVisibilityBE]: {
		map: mapMarkerConfigs.beWeather,
		disableGifFallback: true,
		skipToEnd: true,
		options: { sizes: { wide: [600], medium: [400], small: [300] } },
	},

	//Satellite
	[Radars.SatInfraRed]: {},
	[Radars.SatSouthAmerica]: {},
	[Radars.SatNorthAmerica]: {},
	[Radars.SatAsiaOceania]: {},
	[Radars.SatVisual]: {},
	[Radars.SatCombined]: {},
	[Radars.SatMapVisual15m]: {},

	// Rain Satellite EUMETSAT
	[Radars.SatRainCombined]: {
		map: mapMarkerConfigs.eumetsat,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.eumetsat,
	},

	[Radars.RadarMapRain24HourForecastNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapSun24HourForecastNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapCloud24HourForecastNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapTemperature24HourForecastNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapRain24HourForecastBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapSun24HourForecastBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapCloud24HourForecastBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapTemperature24HourForecastBE]: {
		map: mapMarkerConfigs.beRadar,
	},

	// NL Radar Webmercator NL
	[Radars.RadarMapRain24HourForecastWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapCloud24HourForecastWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapSun24HourForecastWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapTemperature24HourForecastWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapRain5mNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		options: { radarLocationsNL: true },
	},

	//NL Radar Webmercator BE
	[Radars.RadarMapRain1hBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
	},
	[Radars.RadarMapCloud24HourForecastWebmercatorBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
	},
	[Radars.RadarMapSun24HourForecastWebmercatorBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
	},
	[Radars.RadarMapTemperature24HourForecastWebmercatorBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
	},
	[Radars.RadarMapRain15mBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
	},

	// NL Radar
	[Radars.RadarMapRainNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapCloudHeight5mNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapHailNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapLightningNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapDrizzleNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapSnowNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapSunNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapCloudNL]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},
	[Radars.RadarMapRainNLEightHour]: {
		map: mapMarkerConfigs.nlRadar,
		options: { radarLocationsNL: true },
	},

	// BE Radar
	[Radars.RadarMapRainBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapLightningBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapHailBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapDrizzleBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapSnowBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapSunBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapCloudBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapRainBEEightHour]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapDrizzle5mBE]: { map: mapMarkerConfigs.beRadar },
	[Radars.RadarMapSnow5mBE]: { map: mapMarkerConfigs.beRadar },

	// EU Radar
	[Radars.RadarMapRainEU]: { map: mapMarkerConfigs.euRadar },
	[Radars.RadarMapSnowEU]: { map: mapMarkerConfigs.euRadar },
	[Radars.RadarMapCloudEU]: { map: mapMarkerConfigs.euRadar },

	// NL Webmercator
	[Radars.RadarMapSunWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 0.7 },
	},
	[Radars.RadarMapCloudWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 0.7 },
	},
	[Radars.RadarMapRainWebmercatorNLEightHour]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 1.0 },
	},
	// BE Webmeractor
	[Radars.RadarMapRain5mBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 0.7 },
	},
	[Radars.RadarMapCloud5mBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 0.7 },
	},
	[Radars.RadarMapSun5mBE]: {
		map: mapMarkerConfigs.beRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 0.7 },
	},

	// EU Webmercator
	[Radars.RadarMapRain15mNWEU]: {
		map: mapMarkerConfigs.euRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.eu,
		options: { opacity: 0.7 },
	},
	[Radars.RadarMapSnowWebmercatorEU]: {
		map: mapMarkerConfigs.euRadarWebMercator,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.eu,
		options: { opacity: 0.7 },
	},
	[Radars.RadarMapRainCombinedWebmercatorEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euCombined,
	},

	// GFS 7 Days
	[Radars.RadarMapRain3hEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},
	[Radars.RadarMapCloud3hEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},

	[Radars.RadarMapTemperature3hEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},
	[Radars.RadarMapCloudWebmercatorGfsEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},
	[Radars.RadarMapWindPressure3hEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},
	[Radars.RadarMapSnowDepth3hEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},
	[Radars.RadarMapLightningChance3hEU]: {
		map: mapMarkerConfigs.euGfs,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.euGfs,
	},

	// AirPortal Airquality mapMarkerConfigs
	[Radars.AirQualityWebmercatorNL]: {
		map: mapMarkerConfigs.nlRadarWebMercator,
		leafletConfig: leafletConfigs.nl,
		options: { opacity: 0.7 },
	},

	// Leaflet only
	leafletnl: {
		map: mapMarkerConfigs.nlRadar,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.nl,
		maponly: true,
	},
	leafleteu: {
		map: mapMarkerConfigs.euRadar,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.eu,
		maponly: true,
	},
	leafletworld: {
		map: mapMarkerConfigs.eumetsat,
		disableGifFallback: true,
		leafletConfig: leafletConfigs.eumetsat,
		maponly: true,
	},
};
